import { createRouter, createWebHistory } from "vue-router";
import PageNotFound from "./views/PageNotFound.vue";
import AppHome from "./views/AppHome.vue";
import ProgettoDigitalizzazione from "./views/ProgettoDigitalizzazione.vue";
import GuidaPortale from "./views/GuidaPortale.vue"
import GiornaleSpettacolo from "./views/GiornaleSpettacolo.vue"
import BorsaFilm from "./views/BorsaFilm.vue"
import PrivacyPolicy from "./views/PrivacyPolicy.vue"
import PaginaInAllestimento from "./views/PaginaInAllestimento.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:pathMatch(.*)*', 
      name: 'page-not-found', 
      component: PageNotFound 
    },
    {
      path: "/",
      name: "home",
      component: AppHome,
    },
    {
      path: "/progetto-digitalizzazione",
      name: "progetto-digitalizzazione",
      component: ProgettoDigitalizzazione,
    },
    {
      path: "/guida-portale",
      name: "guida-portale",
      component: GuidaPortale,
    },
    {
      path: "/giornale-dello-spettacolo",
      name: "giornale-dello-spettacolo",
      component: GiornaleSpettacolo,
    },
    {
      path: "/borsa-film",
      name: "borsa-film",
      component: BorsaFilm,
    },    
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
    },
        
    {
      path: "/allestimento",
      name: "allestimento",
      component: PaginaInAllestimento,
    },
  ],
});
export { router };