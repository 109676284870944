<script>
import axios from 'axios';
export default {
  name: "AppHeader",
  data() {
    return {
      isLoggedIn: false
    };
  },
  methods: {
    
    checkIfLoggedIn() {
        const currentUrl = window.location.href;
        const params = new URL(currentUrl).searchParams;
        const storage = localStorage.getItem('utente');
        const param = params.get('logged');

        if(param == 1) {
            this.isLoggedIn = true;
            localStorage.setItem('utente', true);
        }    
        if(storage == true) {
            this.isLoggedIn = true;
        }
    },

    changeLocalStorage() {
        localStorage.clear();
    }
  },
  mounted() {
    this.checkIfLoggedIn(); // Controlla lo stato di login al montaggio del component
  },
};

</script>

<template>
  <header>
    <div class="banner d-none d-md-block">
                <div
                    class="container d-flex align-items-center justify-content-end">
                    <div class="login" v-if="!isLoggedIn">
                        <a href="https://rivistespettacolo.it:8443/anec/login"
                            class="link-banner-unstyled">
                            <font-awesome-icon icon="fa-solid fa-user" />
                            accedi/registrati
                        </a>
                    </div>
                    <div class="login" v-else="isLoggedIn">
                        <a href="https://rivistespettacolo.it:8443/anec/ricerca/riviste"
                            class="link-banner-unstyled" v-click="this.changeLocalStorage()">
                            <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="mx-auto" />
                            torna al portale
                        </a>
                    </div>
                </div>
            </div>
    <nav class="navbar py-0" id="my_nav">
                <div class="d-xl-none d-flex container-fluid">
                    <div class="logo_mobile">
                        <img src="../assets/img/Risorsa5 copia.png" alt
                            height="50px">
                        <img
                            src="../assets/img/Giornale-dello-spettacolo-LOGO.jpg"
                            alt height="50px">
                    </div>
                    <div class="menu_hamb">
                        <button class="btn" type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasExample"
                            aria-controls="offcanvasExample">
                            <font-awesome-icon icon="fa-solid fa-bars" />
                        </button>
                    </div>

                    <div class="offcanvas offcanvas-start text-white"
                        tabindex="-1"
                        id="offcanvasExample"
                        aria-labelledby="offcanvasExampleLabel">
                        <div class="offcanvas-header text-white">
                            <button type="button"
                                class="btn-close btn-close-white"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body text-white" data-bs-dismiss="offcanvas">
                            <div>
                                <img src="../assets/img/Borsafilm_bianco.png" alt
                                    height="50px">
                                <img src="../assets/img/Risorsa 1.png" alt
                                    height="30px" class="pt-2 pt-sm-0">
                            </div>
                            <ul
                                class="list-unstyled pt-4">
                                <li class=" ps-3 pb-3">
                                    <router-link :to="{'name': 'progetto-digitalizzazione'}"
                                        class="link-offcanvas-unstyled">
                                        Progetto di Digitalizzazione
                                    </router-link>
                                </li>
                                <li class=" ps-3 pb-3 active">
                                    <a
                                        href="#"
                                        class="link-offcanvas-unstyled">
                                        Le Riviste

                                    </a>
                                    <ul>
                                        <li>
                                            <router-link :to="{'name': 'borsa-film'}"
                                                class="link-offcanvas-unstyled">
                                                Borsa Film
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{'name': 'giornale-dello-spettacolo'}"
                                                class="link-offcanvas-unstyled">
                                                Giornale dello spettacolo
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class=" ps-3 pb-3">
                                    <router-link :to="{'name': 'guida-portale'}"
                                        class="link-offcanvas-unstyled">
                                        Il Portale
                                    </router-link>
                                </li>
                                <li class=" ps-3 pb-3">
                                    <router-link :to="{'name': 'privacy-policy'}"
                                        class="link-offcanvas-unstyled">
                                        Privacy Policy
                                    </router-link>
                                </li>
                            </ul>
                            <ul
                                class="list-unstyled pt-4">
                                <li class=" ps-3 pb-3" v-if="!isLoggedIn">
                                    <a
                                        href="https://rivistespettacolo.it:8443/anec/login"
                                        class="link-offcanvas-unstyled">
                                        Accedi/registrati
                                    </a>
                                </li>
                                <li class=" ps-3 pb-3" v-else>
                                    <a
                                        href="https://rivistespettacolo.it:8443/anec/ricerca/riviste"
                                        class="link-offcanvas-unstyled">
                                        Torna al portale
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xl_container d-none d-xl-flex container">
                    <div class="logo">
                        <img src="../assets/img/Risorsa5 copia.png" alt
                            height="90px">
                        <img
                            src="../assets/img/Giornale-dello-spettacolo-LOGO.jpg"
                            alt>
                    </div>
                    <ul
                        class="list-unstyled d-flex nav_list justify-content-center mb-0">
                        <li class="nav-item px-4 pb-3 me-1">
                          <router-link :to="{'name': 'progetto-digitalizzazione'}" class="link-nav-unstyled">
                                Progetto di Digitalizzazione
                          </router-link>
                        </li>
                        <li
                            class="nav-item px-4 pb-3 me-1 position-relative dropdown_item">
                            <a href="#" class="link-nav-unstyled">
                                Le Riviste <font-awesome-icon icon="fa-solid fa-caret-down" />
                            </a>
                            <div
                                class="dropdown position-absolute bg-white py-3 px-1"
                                style="z-index: 9999; width: 100%; left: 0;">
                                <ul class="dropdown_list">
                                    <li>
                                        <router-link :to="{'name': 'borsa-film'}"
                                            class="dropdown_links">Borsa
                                            Film</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{'name': 'giornale-dello-spettacolo'}"
                                            class="dropdown_links">Giornale
                                            dello Spettacolo</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item px-4 pb-3 me-1">
                            <router-link :to="{'name': 'guida-portale'}"
                                class="link-nav-unstyled">
                                Il Portale
                            </router-link>
                        </li>
                        <li class="nav-item px-4 pb-3 me-1">
                            <router-link :to="{'name': 'privacy-policy'}"
                                class="link-nav-unstyled">
                                Privacy Policy
                            </router-link>
                        </li>
                    </ul>
                </div>
            </nav>
  </header>
  <RouterView />
  <div class="up-button">
            <a href
                class="up_link d-flex align-items-center justify-content-center">
                <font-awesome-icon icon="fa-solid fa-chevron-up" class="mx-auto" />
            </a>
        </div>
</template>

<style lang="sass"></style>