<script>
export default {};
</script>
<template>
<main>
    <div class="container pt-5">
                <p class="my_breadcrumb"><router-link
                        :to="{ name: 'home' }"
                        class="home_breadcrumb">Home</router-link> > Progetto di
                    Digitalizzazione</p>
                <h1 class="page_title">PROGETTO DI <span
                        id="bold_span" style="word-break: break-word;">DIGITALIZZAZIONE</span></h1>
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <img src="../assets/img/278_eds_gamma_026_v-_2_m.jpg" alt="" class="mt-5 shadow d-block" style="width: 100%">
                        <small>Scanner Metis</small>
                    </div>
                    <div class="col-12 col-lg-7">
                        <p class="mt-5">Il progetto di digitalizzazione di
                            <strong>Borsa Film</strong> e <strong>Giornale dello
                                Spettacolo</strong> nasce dalla volontà di ANEC
                            e Cinetel, con il contributo del MIC, di rendere
                            fruibili a tutti, attraverso una facile
                            consultazione, le notizie, le curiosità e gli
                            incassi di <strong>oltre 50 anni di cinema</strong>
                            che hanno segnato un'epoca. La digitalizzazione è
                            avvenuta attraverso un lavoro preciso e meticoloso:
                            sono stati scannerizzati tutti i volumi dell'epoca,
                            utilizzando gli <strong>scanner</strong> e la
                            <strong>tecnologia Metis</strong> per favorire
                            l'elevata qualità dei volumi cartacei.
                        </p>

                        <p class="mt-5">

                            <h4>QUALCHE NUMERO</h4>

                            <strong>VOLUMI DIGITALIZZATI:</strong><span style="font-size: 1.5rem; color: var(--secondary)" class="ms-2">81</span> <br>
                            <strong>PERIODO DI COPERTURA:</strong><span style="font-size: 1.5rem; color: var(--secondary)" class="ms-2">1951 – 2010</span> <br>
                            <strong>NUMERO EDIZIONI:</strong><span style="font-size: 1.5rem; color: var(--secondary)" class="ms-2">3.389</span>  <br>
                            <strong>CARATTERI ESTRATTI:</strong><span style="font-size: 1.5rem; color: var(--secondary)" class="ms-2">455.857.000</span> <br>

                        </p>

                        <p class="mt-5 mb-5">
                            <h4>THANKS TO</h4>
                            TAMBO TLC <br>
                            CNI <br>
                            TRE EFFE INFORMATICA
                        </p>
                    </div>
                </div>
            </div>
</main>
</template>

<style lang="scss" scoped>
</style>