<script>
export default {};
</script>
<template>
  <main id="main">
    <div class="container pt-5">
      <p class="my_breadcrumb">
        <router-link :to="{ name: 'home' }" class="home_breadcrumb"
          >Home</router-link
        >
        > Borsa Film
      </p>
      <h1 class="page_title">BORSA <span id="bold_span">FILM</span></h1>
      <div class="row pt-4">
        <div class="col-12 col-md-8">
          <p>
            La Borsa Film ha rappresentato per quattro decenni
            <strong
              >un compendio dell’andamento del mercato cinematografico in
              Italia,</strong
            >, dagli anni in cui l’unica forma di concorrenza era rappresentata
            dalla appena nata televisione agli anni ben più sfidanti per il
            consumo del cinema in sala e del tempo libero in generale. La
            chiusura delle pubblicazioni è avvenuta nel 1995, poco dopo la
            nascita di Cinetel. <br /><br />
            La rilevazione dati, avviata negli anni 50’, è stata concentrata
            inizialmente alle cosiddette <strong>"città capozona"</strong>, le
            città principali che inizialmente erano 15: Roma, Milano, Torino,
            Genova, Padova, Venezia, Trieste, Bologna, Firenze, Ancona, Napoli,
            Bari, Palermo, Catania, Cagliari (cui si aggiunse in seguito
            Messina), e “da una settimana all’altra” si raccoglievano gli
            incassi complessivi così come dei singoli film - “Settebello” era il
            titolo della rubrica che ne riepilogava i maggiori incassi.
            <br /><br />
            Sin da subito, gli incassi sono stati suddivisi per
            <strong>Casa di distribuzione</strong>: ad ottobre del 1958 se ne
            contavano 19 a livello nazionale (cui andavano aggiunti i film
            affidati ai “distributori regionali”). Tra le distribuzioni,
            naturalmente, avevano ampio risalto e riscontro le major e le loro
            filiali - Ceiad, Metro Goldwyn Mayer, Paramount, 20th Century Fox,
            Universal, Warner Bros, oltre alla Rank Film e alla Dear
            International - e poi le italiane Cineriz, Dino De Laurentiis, Euro
            International, Lux Film, Titanus: sigle gloriose che hanno fatto la
            storia del cinema. <br /><br />
            A scorrere le pubblicazioni della Borsa Film, si nota
            <strong
              >l'evoluzione del mercato della distribuzione
              cinematografica</strong
            >, non solo nell'<strong>avvicendarsi delle ragioni sociali</strong>
            (Medusa, le varie CIC-UIP, l'Italnoleggio, l'Italian Film
            International, Cecchi Gori Distribuzione) ma anche nella
            <strong>progressiva diversificazione dell'offerta</strong>, nella
            <strong>crescita del numero di film distribuiti</strong>, nel
            graduale <strong>emergere delle società indipendenti</strong>. E
            soprattutto
            <strong>l'allargamento costante nel tempo dei dati rilevati</strong>
            con le analisi per nazionalità e le capozona che diventano 12,
            mentre fra le <strong>"città chiave"</strong> progressivamente
            acquisiscono rilevanza e visibilità le realtà di profondità, grazie
            all'affermarsi delle multisale e poi dei multiplex, raggiungendo e
            superando il numero di 100. <br /><br />
            Con la <strong>stagione cinematografica 1995-1996</strong>, le
            <strong>rilevazioni di mercato</strong>
            vengono affidate a <strong>Cinetel Srl</strong>, società nata dalla lungimirante
            compartecipazione delle associazioni della distribuzione e
            dell'esercizio cinematografici: <strong>inizia a delinearsi la struttura del
              mercato come oggi la conosciamo, e la storia diventa cronaca</strong>. <br><br>
              Negli anni di pubblicazione della Borsa Film i dati sono stati costantemente raccolti ed elaborati da Franco Scampini, coadiuvato da Fabrizio Crucianelli, e commentati da Alessandro Ferraù. 
          </p>
        </div>
        <div class="col-12 col-md-4 mb-5">
          <img
            src="../assets/img/borsafilm_cop.jpg"
            alt
            style="max-width: 100%"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped></style>
