<script>
export default {};
</script>
<template>
  <main>
    <div class="container pt-5">
      <p class="my_breadcrumb">
        <router-link :to="{ name: 'home' }" class="home_breadcrumb"
          >Home</router-link
        >
        > Privacy Policy
      </p>
      <h1 class="page_title">
        PRIVACY
        <span id="bold_span" style="word-break: break-word">POLICY</span>
      </h1>
      <div class="row">
        <div class="col-12">
          <p class="mt-5">
            <strong
              >Informativa sul Trattamento dei Dati Personali
              <em>ai sensi degli artt. 13 e 14 del Regolamento UE 2016/679</em>
            </strong>
          </p>
          <p class="text-justify">
            <strong>Introduzione</strong><br />
            Si informa l'Interessato, utente o visitatore del sito web, che il
            Regolamento UE 2016/679 (di seguito, "<strong>GDPR</strong>")
            prevede la tutela del trattamento dei suoi dati personali. In
            particolare, il GDPR, insieme al D.lgs. n. 196/2003 (c.d. "Codice in
            materia di protezione dei Dati Personali", di seguito,
            "<strong>Codice</strong>") e l'ulteriore normativa vigente in
            materia, stabiliscono che il trattamento di dati personali sia
            improntato ai principi di correttezza, liceità e trasparenza nel
            rispetto dei diritti e delle libertà fondamentali, della dignità
            dell'Interessato, con particolare riferimento alla riservatezza,
            all'identità personale e al diritto alla protezione dei dati
            personali. <br />Questa informativa (di seguito,
            l'"<strong>Informativa</strong>") è resa ai sensi degli artt. 13 e
            14 del GDPR, ed è soggetta ad aggiornamenti dei quali è data
            pubblicità sul Sito Web. Si consiglia, quindi, di controllare
            regolarmente l'Informativa e di riferirsi alla versione più
            aggiornata.
          </p>
          <ol>
            <li>
              <strong>Definizioni</strong><br />
              <p>Ai fini dell'Informativa si intende per:</p>
              <p>
                “<strong>Banca Dati</strong>”: qualsiasi complesso organizzato
                di Dati Personali, ripartito in una o più unità dislocate in uno
                o più siti del Titolare;
              </p>
              <p>“<strong>Comunicazione</strong>”: qualsiasi divulgazione idonea a
              dare conoscenza dei Dati Personali a uno o più soggetti
              determinati diversi dall'Interessato, dal rappresentante del
              Titolare nel territorio dello Stato, dal Responsabile e dagli
              Incaricati, in qualunque forma, anche mediante la messa a
              disposizione o consultazione dei Dati Personali;</p>
              <p>“<strong>Consenso</strong>”: qualsiasi manifestazione di volontà
              libera, specifica, informata e inequivocabile dell'Interessato,
              mediante dichiarazione o azione positiva inequivocabile, con la
              quale quest'ultimo manifesti il proprio assenso affinché i Dati
              Personali che lo riguardano siano oggetto di Trattamento;</p>
              <p>“<strong>Dato Personale</strong>”: qualunque informazione relativa
              a una persona fisica, identificata o identificabile, anche
              indirettamente, mediante riferimento a qualsiasi altra
              informazione;</p>
              <p>“<strong>Incaricato</strong>”: la persona fisica autorizzata a
              compiere operazioni di Trattamento dal Titolare del Trattamento o
              dal Responsabile;</p>
              <p>“<strong>Interessato</strong>”: la persona fisica cui si
              riferiscono i Dati Personali; “Responsabile”, la persona fisica,
              la persona giuridica, la pubblica amministrazione e qualsiasi
              altro ente, associazione o organismo preposti al Trattamento dal
              Titolare del Trattamento; </p>
              <p>“<strong>Responsabile della Protezione dei Dati (DPO)</strong>”:
              il soggetto incaricato dal Titolare del Trattamento ai sensi degli
              artt. 37-39 del GDPR;</p> <p>“<strong>Sito Web</strong>”: il sito
                https://www.rivistespettacolo.it/; </p>
              <p>“<strong>Titolare del Trattamento</strong>”: la persona fisica, la
              persona giuridica, la pubblica amministrazione e qualsiasi altro
              ente, associazione od organismo cui competono, anche unitamente ad
              altro/i Titolare/i del Trattamento, le decisioni in ordine alle
              finalità, alle modalità del Trattamento di Dati Personali e agli
              strumenti utilizzati, ivi compreso il profilo della sicurezza;</p>
              <p>“<strong>Trattamento</strong>”: qualunque operazione o complesso
              di operazioni, effettuati anche senza l'ausilio di strumenti
              elettronici, concernenti la raccolta, la registrazione,
              l'organizzazione, la conservazione, la consultazione,
              l'elaborazione, la modificazione, la selezione, l'estrazione, il
              raffronto, l'utilizzo, l'interconnessione, il blocco, la
              comunicazione, la diffusione, la cancellazione e la distruzione di
              dati, anche se non registrati in una Banca Dati;</p>
              <p>“<strong>Utente</strong>”: qualsiasi persona fisica che utilizzi
                il Sito Web.</p>
              
            </li>
            <li class="pt-3">
                <strong>Titolare del trattamento</strong>
                <ol>
                    <li>Il Titolare del Trattamento è Anec Servizi S.r.l., con sede legale in Via di Villa Patrizi 10 - 00161 Roma, Codice Fiscale e Partita IVA 00885071001 (di seguito, “<strong>Titolare</strong>” o “<strong>Società</strong>”).</li>
                    <li>Le informazioni e le richieste in materia di privacy, anche relative all'esercizio dei diritti dell'Interessato indicati al successivo punto 9, possono essere rivolte alla Società tramite posta elettronica all'indirizzo <a href="mailto:amministrazione@anec.it" style="color: var(--secondary)">amministrazione@anec.it</a>.</li>
                </ol>
            </li>
            <li class="pt-3">
                <strong>Luogo di Trattamento dei Dati e loro trasferimento</strong>
                <ol>
                    <li>Il Trattamento dei Dati Personali avviene su server ubicati all'interno dell'Unione Europea. Attualmente, i server sono ubicati in Italia. I Dati Personali non sono trasferiti fuori dall'Unione Europea. Il Titolare si riserva la facoltà di modificare l'ubicazione dei server anche fuori dall'Unione Europea, assicurando, in tal caso, che il trasferimento avvenga in conformità alle disposizioni di legge applicabili, con le adeguate garanzie previste dall'art. 46 del GDPR. </li>
                </ol>
            </li>
            <li class="pt-3">
                <strong>Tipologia di Dati Personali trattati e finalità del Trattamento</strong>
                <ol>
                    <li><em class="under_line">Dati Personali di navigazione</em>
                        <ol>
                            <li>I sistemi informatici e le procedure software preposte al funzionamento del Sito Web acquisiscono, nel corso del loro normale esercizio, alcuni Dati Personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a Interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni e associazioni con dati detenuti da terzi, permettere di identificare gli Utenti. In questa categoria di Dati Personali rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli Utenti che si connettono al Sito Web delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) e altri parametri relativi al sistema operativo e all'ambiente informatico dell'Utente. Questi Dati Personali vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del Sito Web e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l'elaborazione. Questi Dati Personali potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni del Sito Web. In ogni caso, questi Dati Personali sono conservati per il periodo strettamente necessario e comunque in conformità̀ alle vigenti disposizioni normative in materia.</li>
                        </ol>
                    </li>
                    <li><em class="under_line">Cookies</em>
                        <ol>
                            <li>Dettagli completi su questa tipologia di Dati sono forniti nella sezione del Sito Web dedicata alla “cookie policy” e mediante specifico testo informativo visualizzato prima della raccolta dei cookies per i quali è richiesto il rilascio del Consenso da parte dell'Utente.</li>
                        </ol>
                    </li>
                    <li><em class="under_line">Dati Personali forniti volontariamente dagli Utenti</em>
                        <ol>
                            <li>L'invio volontario, facoltativo ed esplicito di Dati Personali da parte dell'Utente (es. in occasione dell'inserimento dei propri Dati Personali mediante compilazione di appositi moduli, l'invio di posta elettronica agli indirizzi indicati sul Sito Web, l'iscrizione al Sito Web per fruire dei servizi offerti ecc.) comporta la successiva acquisizione dell'indirizzo del mittente e dei Dati Personali dallo stesso forniti, trattati dal Titolare per dare seguito ad una richiesta dell'Interessato o nel perseguimento di un legittimo interesse del Titolare a condizione che non prevarichino gli interessi o i diritti o le libertà dell'Interessato.</li>
                        </ol>
                    </li>
                    <li><em class="under_line">Dati Personali raccolti tramite registrazione nel Sito Web</em><br>
                        Con la registrazione nella apposita sezione del Sito Web, l’Utente fornisce spontaneamente alcuni Dati Personali quali, a titolo esemplificativo e non esaustivo, il nome, il cognome e l’indirizzo e-mail, che il Titolare è legittimato a trattare al fine di completare e gestire il processo di accreditamento e accesso. 
                        <ol>
                            <li>I Dati Personali sono raccolti e trattati per le seguenti finalità: 
                                <ol class="list_letters">
                                    <li>gestione degli accessi dell’Utente al Sito Web per consentire all’Utente di fruire dei servizi relativi e partecipare ad eventuali iniziative riservate;</li>
                                    <li>gestione delle richieste di contatto inviate dall’Utente.</li>
                                </ol>
                            </li>
                            <li>In ogni caso, laddove il Titolare del Trattamento intendesse trattare ulteriormente i Dati Personali per una finalità diversa da quella per cui essi sono stati raccolti, il Titolare fornirà all’Interessato, prima di tale ulteriore Trattamento, le informazioni in merito a tale diversa finalità, nonché ogni ulteriore informazione pertinente.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li class="pt-3">
                <strong>Modalità, e durata del Trattamento</strong>
                <ol>
                    <li>I Dati Personali raccolti sono: 
                        <ol class="list_letters">
                            <li>trattati mediante strumenti elettronici automatizzati, informatici e telematici, o mediante elaborazioni manuali con logiche correlate alle finalità per le quali i Dati Personali sono stati raccolti;</li>
                            <li>trattati in modo lecito, corretto e trasparente con riferimento all’Interessato;</li>
                            <li>raccolti per finalità determinate, esplicite e legittime, e successivamente trattati in modo coerente con tali finalità;</li>
                            <li>adeguati, pertinenti e limitati a quanto necessario rispetto alle finalità per le quali sono trattati;</li>
                            <li>esatti e, se necessario, aggiornati;</li>
                            <li>conservati in modo da consentire l’identificazione dell’Interessato per un periodo di tempo non superiore al conseguimento delle finalità per le quali sono trattati; </li>
                            <li>trattati in maniera da garantire un’adeguata sicurezza dei Dati Personali – compresa la protezione, mediante misure tecniche e organizzative adeguate - da trattamenti non autorizzati o illeciti e dalla perdita, dalla distruzione o dal danno accidentali.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li class="pt-3">
                <strong>Facoltatività del conferimento dei Dati</strong>
                <ol>
                    <li>Fatto salvo per i Dati Personali di navigazione, la comunicazione dei Dati Personali da parte dell’Interessato è facoltativa, ma l’eventuale rifiuto potrebbe impedire di provvedere alla puntuale e corretta gestione della richiesta di contatto e impedire l’accesso dell’Utente al Sito Web.</li>
                </ol>
            </li>
            <li class="pt-3">
                <strong>Eventuali destinatari dei Dati Personali</strong>
                <ol>
                    <li>I Dati Personali non saranno oggetto di Comunicazioni a terzi, salvo che ciò sia indispensabile e, in tal caso, la Comunicazione avrà ad oggetto esclusivamente i Dati Personali strettamente necessari a realizzare le finalità del Trattamento indicate nell’Informativa. E quindi i Dati Personali potranno essere comunicati:
                        <ol class="list_letters">
                            <li>a terzi che, su incarico della Società eroghino in favore della Società servizi necessari al manutenzione o alla fruizione della Società;</li>
                            <li>ad autorità amministrative o giudiziarie per l’adempimento di obblighi di legge.</li>
                        </ol>
                    </li>
                    <li>I destinatari di dette comunicazioni tratteranno i Dati Personali in qualità di Responsabili del Trattamento ex art. 28 del GDPR. L’elenco dei Responsabili potrà essere fornito su richiesta.</li>
                </ol>
            </li>
            <li class="pt-3">
                <strong>Sicurezza delle informazioni</strong>
                <ol>
                    <li>Tutte le informazioni raccolte sul Sito Web vengono memorizzate e mantenute in strutture sicure che limitano l’accesso esclusivamente al personale autorizzato. Il Sito Web è regolarmente monitorato per verificare l’eventuale presenza di violazioni della sicurezza e assicurare che le informazioni raccolte siano al sicuro da chi intenda visualizzarle senza autorizzazione. Il Titolare si attiene alle misure di sicurezza prescritte dalle leggi e dalle normative applicabili e a tutte le misure adeguate secondo i criteri attualmente più all’avanguardia, per assicurare e garantire la riservatezza dei Dati Personali dell’Utente e ridurre al minimo, per quanto possibile, i pericoli costituiti dall’accesso non autorizzato, dalla rimozione, dalla perdita e/o dal danneggiamento dei Dati Personali dell’Utente.</li>
                    <li>A norma dell’art. 32 del GDPR, tenendo conto dello stato dell’arte e dei costi di attuazione, nonché della natura, dell’ambito di applicazione, del contesto e delle finalità del Trattamento dei Dati Personali, come anche dei rischi aventi probabilità e gravità diverse per i diritti e le libertà delle persone fisiche interessate dal Trattamento dei Dati Personali, sia al momento di determinare i mezzi del Trattamento sia all’atto del Trattamento stesso, il Titolare mette in atto misure tecniche e organizzative adeguate, volte ad attuare in modo efficace i principi di protezione dei Dati Personali.</li>
                </ol>
            </li>
            <li class="pt-3">
                <strong>Diritti dell'Interessato</strong>
                <ol>
                    <li>L’Interessato può, in ogni momento, esercitare i diritti enunciati dall’art. 7 del Codice e dal Capo III del GDPR. In particolare, l’Interessato ha diritto di:
                    <p><strong>accesso</strong> ai Dati Personali che lo riguardano per ottenere informazioni sulle modalità e finalità del Trattamento;</p>
                    <p><strong>rettifica</strong> dei Dati Personali forniti, qualora inesatti;</p>
                    <p><strong>cancellazione</strong> dei Dati Personali forniti, i quali dovranno essere cancellati nei casi di revoca da parte dell’Interessato del proprio Consenso al Trattamento dei Dati Personali o di sua opposizione al Trattamento degli stessi (a cui si aggiunge l’obbligo di cancellazione dei Dati Personali nei casi di Trattamento illecito degli stessi o di sussistenza di un obbligo legale di cancellazione);</p>
                    <p><strong>limitazione</strong> del Trattamento dei Dati Personali, quando ricorre una delle condizioni di cui all’art. 18 del GDPR; </p>
                    <p><strong>opposizione</strong>, in qualunque momento, al Trattamento dei Dati Personali, salvo che vi sia un interesse legittimo del Titolare a procedere al Trattamento che prevalga sul diritto di opposizione;</p>
                    <p><strong>portabilità</strong>, che consiste nel diritto dell’Interessato di chiedere di ricevere i Dati Personali, o di farli trasmettere a un altro Titolare del Trattamento a tal fine indicato, in un formato strutturato, di uso comune e leggibile da dispositivo automatico.</p>
                </li>
                <li>
                    L’Interessato ha altresì diritto di proporre reclamo dinanzi all’Autorità di controllo che per l’Italia è il Garante per la Protezione dei Dati Personali Personali e può essere contattata al sito <a target="_blank" href="https://www.garanteprivacy.it" style="color: var(--secondary)">www.garanteprivacy.it</a>.
                </li>
                </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
p {
    text-align: justify;
    ol {
        li {
            p {
                text-align: justify;
            }
            
        }
    }
}
/* Reset del contatore per il tag ol */
ol {
    counter-reset: item;
  }

  /* Incremento del contatore per ogni elemento li */
  li {
    display: block;
  }

  /* Stile della lista di base */
  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }

  /* Stile della sottolista */
  li li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }

.under_line {
    text-decoration: underline;
}

.list_letters {
    list-style: lower-alpha !important;
}
</style>
