<script>
export default {};
</script>
<template>
  <main id="main">
    <div class="container pt-5">
      <p class="my_breadcrumb">
        <router-link :to="{ name: 'home' }" class="home_breadcrumb"
          >Home</router-link
        >
        > Il Giornale dello Spettacolo
      </p>
      <h1 class="page_title">
        IL GIORNALE DELLO <span id="bold_span">SPETTACOLO</span>
      </h1>
      <div class="row pt-4">
        <div class="col-12 col-md-8">
          <p>
            Il "Giornale dello Spettacolo" è nato il 15 agosto 1945 a Milano
            come "Bollettino di Informazione", quindicinale dell'Associazione
            Lombarda Esercenti Cinema. <br />Nel 1952 cambia nome in "Bollettino
            dello Spettacolo", quindicinale di informazione dell'AGIS
            (Associazione generala italiana dello spettacolo). <br />Nel 1953 la
            periodicità diventa settimanale e la redazione si sposta a Roma.
            <br />Nel 1955 diventa "Giornale dello Spettacolo" e acquisisce il
            ruolo che, con crescente importanza, l'ha caratterizzato nei
            decenni: quello di un prezioso punto di riferimento del mondo dello
            spettacolo e della cultura in Italia:
            <strong
              >cinema, prosa, musica, danza, spettacoli viaggianti e arti
              circensi</strong
            >
            hanno trovato spazio sin dalla fondazione dell'AGIS e dell'ANEC, in
            un contesto associativo di confronto, comunicazione e informazione
            al pubblico.
            <br /><br />
            <strong
              >Attraverso le pubblicazioni del settimanale del cinema e dello
              spettacolo emerge un importante spaccato di storia del nostro
              Paese, della nostra cultura, in tutte le sue distinte
              articolazioni</strong
            >: le attività e i rapporti ministeriali, l'emanazione delle leggi
            di settore e tutto ciò che a loro è collegato; le modalità di
            attuazione delle normative di settore; la politica attiva delle
            associazioni, il panorama industriale e la sua evoluzione nel corso
            del tempo; l'emergere di nuove modalità di fruizione del cinema,
            l'avvento delle televisioni commerciali; i rapporti internazionali,
            le attività festivaliere; le professioni del nostro cinema, il
            divismo degli anni d'oro del cinema italiano, la società italiana
            riflessa attraverso la Settima Arte; il mondo della prosa in Italia
            e la sua crescita artistica e imprenditoriale. <br /><br />
            Sono solo alcuni degli spunti che sono custoditi all'interno dei
            numeri storici conservati del Giornale dello Spettacolo, finora
            consultabili solo negli archivi nazionali e associativi e che adesso
            escono
            <strong
              >dalla “polvere del tempo” per essere affidati alla consultazione
              di professionisti del settore, studiosi, ricercatori, laureandi e
              anche semplici amanti della cultura</strong
            >
            attraverso la loro completa
            <strong>digitalizzazione, a partire dal 1955</strong>. <br /><br />
            Nel corso della sua storia, si sono susseguiti professionisti che
            hanno sviluppato e guidato il Giornale dello Spettacolo. Il
            fondatore e direttore per la lunga, prima fase di vita: l'Avvocato
            <strong>Mario Villa</strong>. A fine 1966 la direzione passa a
            <strong>Franco Bruno</strong>, anche Segretario generale e poi
            Presidente AGIS. Da settembre 1989, Davide Quilleri, alla guida di
            ANEC e a seguire AGIS assume la Direzione, mentre
            <strong>Felice Ambrosino</strong>, ne diventa Direttore
            responsabile, per i seguenti sei anni. Da gennaio 1996, la direzione
            passa a <strong>Luigi Filippi</strong> e successivamente sarà
            <strong>Roberta Romei</strong> a sostituirlo per la fase finale della versione
            cartacea, conclusasi nel dicembre 2010.
          </p>
        </div>
        <div class="col-12 col-md-4 mb-5">
          <img src="../assets/img/giornali.png" alt style="max-width: 100%" />
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped></style>
