window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  const navbar = document.getElementById("my_nav");
  const main = document.getElementById("main");
  const targetPosition = main.getBoundingClientRect().top;
  if (targetPosition < 96 && window.innerWidth > 767) {
    navbar.classList.add("scrolled");
    main.style.paddingTop = "100px";
  }
  if (targetPosition > 37 && window.innerWidth > 767) {
    main.style.paddingTop = "0px";
    navbar.classList.remove("scrolled");
  }
}
