import './assets/scss/app.scss'
import './assets/js/myscript.js'
import { createApp } from 'vue'
import { router } from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronUp, faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';


import App from './App.vue'

import * as bootstrap from 'bootstrap'

library.add(faUser, faCaretDown, faBars, faChevronUp, faRightFromBracket)
createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
