<script>
export default {
}
</script>

<template>
    <main>
        <div class="container text-center">
            <h1 style="color: var(--secondary-shade)">404</h1>
            <span>Pagina non trovata</span>
            <router-link :to="{'name': 'home'}" class="d-block">&LeftArrow; Torna alla Home</router-link>
        </div>
    </main>

</template>


<style lang="scss" scoped>
    h1 {
        font-size: 6rem;
    }
    span {
        font-size: 2rem;
    }
</style>