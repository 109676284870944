<script>
export default {};
</script>
<template>
  <main>
    <div class="container py-5">
        <div class="row pt-lg-5">
            <div class="d-none d-lg-flex col-lg-2"></div>
            <div class="d-none d-lg-flex col-lg-2">
                <img src="../assets/img/constr.png" alt="" srcset="" style="width: 100%;">
            </div>
            <div class="col-12 col-lg-6 ps-lg-4">
                <h1 style="font-size: 3rem; word-break: break-word;"><strong>SITO IN MANUTENZIONE</strong> <br>PRESTO DI NUOVO ONLINE</h1>
                <h4>Ci scusiamo per il disagio</h4>
            </div>
            <div class="d-none d-lg-flex col-lg-2"></div>
        </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>

</style>