<script>
export default {};
</script>
<template>
<main id="main" class="">
            <div class="container pt-5">
                <p class="my_breadcrumb"><router-link
                        :to="{ name: 'home' }"
                        class="home_breadcrumb">Home</router-link> > Il Portale</p>
                <h1 class="page_title">GUIDA AL <span
                        id="bold_span">PORTALE</span></h1>
                <div class="row">
                    <div class="col-12 mt-5">
                        <h4>L'obiettivo</h4>
                        <p>L'obiettivo del portale è quello di fornire uno
                            strumento per gestire tutte le riviste di
                            <strong>Borsa Film</strong> e del <strong>Giornale
                                dello Spettacolo</strong>, pubblicate tra il
                            1951 e il 2010, che, grazie all'ambizioso <router-link :to="{'name': 'progetto-digitalizzazione'}"
                                style="color: var(--secondary)">progetto di
                                digitalizzazione</router-link>, sono state rese in formato
                            digitale.</p>

                        <h4 class="mt-5">Il funzionamento</h4>
                        <p>Per usufruire del portale basteranno poche e semplici
                            mosse.</p>

                        <p><strong>AUTENTICAZIONE: </strong></p>
                        <div class="d-flex gap-3 flex-column flex-lg-row">
                            <div>
                                <img src="../assets/img/registr.png" alt
                                    style="max-width: 100%;" class="shadow">
                            </div>
                            <span>Nel nostro sito, in alto a destra, trovate il
                                link <img src="../assets/img/login.jpg" alt
                                    height="25px"> che vi porterà alla sezione
                                dedicata. <br> <br>
                                Se non siete già registrati, vi basterà
                                compilare il modulo di destra <strong>REGISTRATI
                                    ORA</strong> con le informazioni richieste.
                                Vi arriverà un' e-mail
                                all'indirizzo che avete indicato, dove potrete
                                confermare la registrazione seguendo il link di
                                verifica. Questo link vi reindirizzerà in una
                                nuova pagina per scegliere la password. <br>
                                <br>
                                A questo punto potrete accedere al portale,
                                compilando il form di <strong
                                    style="color: var(--secondary)">EFFETTUA
                                    L'ACCESSO</strong>, che si trova a sinistra,
                                con le vostre credenziali stabilite in fase di
                                registrazione. In questa sezione trovate anche
                                il link al Reset della Password.</span>
                        </div>

                        <p class="mt-5"><strong>UTILIZZO: </strong></p>
                        <div class="d-flex gap-3 flex-column flex-lg-row">
                            <span>Effettuato con successo il login, potrete
                                iniziare ad utilizzare il portale, il quale vi
                                mette a disposizione due differenti metodi di
                                consultazione: <em>Ricerca Riviste</em> e
                                <em>Elenco Riviste</em>. <br><br>
                                <strong>Ricerca riviste</strong><br>
                                La funzionalità di ricerca riviste è suddivisa
                                in due sezioni, che potete trovare nella barra
                                di navigazione nel momento in cui avrete
                                effettuato l'accesso: Ricerca per Parola/Testo
                                Chiave e Ricerca per Attributo. <br> <br>
                                La <strong>ricerca per parola/testo
                                    chiave</strong>, che si trova sulla
                                sinistra, dà la possibilità, inserendo testo o
                                parole chiave nel campo di ricerca, selezionando
                                il tipo di rivista in cui si vuole cercare e
                                premendo il tasto cerca, di visualizzare, nella
                                tabella sottostante, una lista di riviste che
                                corrispondono ai criteri inseriti. Ogni voce
                                nell'elenco avrà le seguenti specifiche: tipo
                                rivista, testata, anno, edizione, data.
                                Per ogni rivista saranno disponibili due
                                pulsanti:

                                <strong>Anteprima</strong> (cliccando su questo
                                pulsante si
                                aprirà una miniatura del PDF della rivista) e
                                <strong>Visualizza</strong> (questo pulsante
                                aprirà il PDF della
                                rivista in una nuova scheda del browser).

                                Il PDF sarà visualizzato direttamente nella
                                pagina dove è presente il testo ricercato.
                                <br><br>

                                La <strong>ricerca per pubblicazione</strong>, che
                                si trova sulla
                                destra, dà la possibilità, inserendo le
                                specifiche dettagliate (es. Testata, Anno,
                                etc.), selezionando
                                il tipo di rivista in cui si vuole cercare e
                                premendo il tasto cerca, di visualizzare, nella
                                tabella sottostante, una lista di riviste che
                                corrispondono ai criteri inseriti. La
                                visualizzazione è la medesima della ricerca per
                                parola/testo chiave.
                            </span>
                            <div id="utilizzo">
                                <img src="../assets/img/ricerca_riviste.png" alt
                                    style="max-width: 100%;" class="shadow">
                            </div>
                        </div>
                        <div class="d-flex gap-3 mt-5 flex-column flex-lg-row mb-5">
                            <span><strong>Elenco Riviste</strong><br>
                                La funzionalità Elenco Riviste permette di visualizzare tutte le riviste suddivise per anni e per tipo:
                                selezionando l'anno da ricercare e il tipo di rivista in cui si vuole cercare verrà visualizzata, nella
                                tabella sottostante, una lista di riviste che
                                corrispondono ai criteri inseriti. Anche qui, ogni voce
                                nell'elenco avrà le seguenti specifiche: tipo
                                rivista, testata, anno, edizione, data.
                                Per ogni rivista saranno disponibili due
                                pulsanti:

                                <strong>Anteprima</strong> (cliccando su questo
                                pulsante si
                                aprirà una miniatura del PDF della rivista) e
                                <strong>Visualizza</strong> (questo pulsante
                                aprirà il PDF della
                                rivista in una nuova scheda del browser).

                                Il PDF sarà visualizzato direttamente nella
                                pagina dove è presente il testo ricercato.
                                <br><br>
                            </span>
                            <div id="elenco">
                                <img src="../assets/img/elenco_riviste.png" ahlt
                                    style="max-width: 100%;" class="shadow">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
</template>

<style lang="scss" scoped></style>