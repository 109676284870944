<script>
export default {
    name: 'AppFooter'
};
</script>

<template>
    <footer>
            <div class="container">
                <div
                    class="footer-top py-3" >
                    <div class="row row-cols-1 row-cols-lg-5">
                        <div class="col d-flex align-items-center justify-content-center">
                            <span
                                class="d-block mt-md-2 address pb-4 pb-mb-0">
                                Promosso da:
                            </span>
                        </div>
                        <div
                            class="footer-logo col d-flex align-items-center justify-content-center py-lg-0">
                            <a target="_blank" href="https://anec.it/">
                                <img src="../assets/img/logoWhite.png"
                                alt="Logo Anec"
                                style="max-height: 100px; max-width: 100%;"
                                class="d-block mx-auto">
                            </a>
                        </div>
                        <div
                            class="giornale col d-flex align-items-center justify-content-center pb-2 pb-lg-0 pt-4 pt-lg-0">
                            <a target="_blank" href="https://www.cinetel.it/">
                                <img src="../assets/img/logo-Cinetel copia.png" alt
                                style="max-width: 100%; max-height: 60px; border-radius: 5px;"
                                class="mx-auto d-block">
                            </a>
                        </div>
                        <div class="col d-flex align-items-center justify-content-center">
                            <span
                                class="d-block mt-md-2 address pb-4 pb-mb-0 pt-4 pt-lg-0">
                                Con il contributo di:
                            </span>
                        </div>
                        <div
                            class="giornale col d-flex align-items-center justify-content-center py-2 py-lg-0">
                            <a target="_blank" href="https://cinema.cultura.gov.it/" class="d-flex align-items-center justify-content-center text-decoration-none">
                                <img
                                    src="../assets/img/MinisteroDellaCulturaDefdef.png"
                                    alt style="max-width: 100%; max-height: 70px;" class="d-block">
                                <div
                                    class="testo d-flex flex-column justify-content-between mt-0"
                                    style="font-family: 'Titillium Web', sans-serif; font-size: 0.85rem; color: var(--lighter);">
                                    <span class="ms-2">Direzione Generale</span>
                                    <span class="ms-2"><strong>CINEMA e
                                            AUDIOVISIVO</strong></span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div class="foot-banner text-center">
            © Copyright ANEC SERVIZI - È vietata la riproduzione di contenuti senza
            autorizzazione <span class="d-none d-lg-inline-block">-</span> <br class="d-lg-none">Developed by: <a
                href="https://www.treeffeinformatica.it/" target="_blank"
                class="forgotten_pass_link" style="font-size: 1rem">Tre Effe
                Informatica</a>
        </div>
</template>

<style lang="sass" scoped></style>